<template>
    <div id="recovery" class="h-100">
        <b-form v-if=" etapa === 1 " class="h-100" @submit=" sendEmail ">
            <div class="container d-flex d-sm-flex flex-row justify-content-center align-items-center align-content-center justify-content-sm-center h-100">
                <div class="form-row">
                    <div class="col-sm-12">
                        <div class="form-row">
                            <div class="col-12 text-center">
                                <div class="logo"></div>
                                <h1>ESQUECI MINHA SENHA</h1>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12 text-center">
                                <p>Enviaremos um email com instruções para alteração de senha.</p>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12 text-center">
                                <b-form-group id="group-email" label="Informe seu email*" label-for="email">
                                    <b-form-input id="email" v-model="form.email" type="email" required :disabled="loading" :state="validEmail"></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12 text-center">
                                <b-button variant="dark" size="lg" block type="submit" :disabled="loading">
                                    ENVIAR
                                    <b-icon v-show="loading" icon="arrow-clockwise" animation="spin" style="float: right; margin-top: 3px"></b-icon>
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-form>
        <b-form v-if=" etapa === 2 " class="h-100" @submit=" validCode ">
            <div class="container d-flex d-sm-flex flex-row justify-content-center align-items-center align-content-center justify-content-sm-center h-100">
                <div class="form-row">
                    <div class="col-sm-12">
                        <div class="form-row">
                            <div class="col-12 text-center">
                                <div class="logo"></div>
                                <p class="no-margin">
                                    As instruções de recuperação de senha foram enviadas para <br/>
                                    <label class="text-success font-weight-bold">{{ this.form.email }}</label>
                                </p>
                                <p>Informe o código recebido no campo abaixo:</p>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12 text-center">
                                <b-form-group id="group-code" label="Informe o código de acesso" label-for="code">
                                    <b-form-input id="code" v-model="form.code" type="text" required :disabled="loading"></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12 text-center">
                                <b-button variant="dark" size="lg" block type="submit" :disabled="loading">
                                    CRIAR NOVA SENHA
                                    <b-icon v-show="loading" icon="arrow-clockwise" animation="spin" style="float: right; margin-top: 3px"></b-icon>
                                </b-button>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12 text-center">
                                <b-button variant="link" size="lg" block type="button" :disabled="loading" @click=" resendEmail ">Reenviar código</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-form>
        <b-form v-if=" etapa === 3 " class="h-100" @submit=" validPass ">
            <div class="container d-flex d-sm-flex flex-row justify-content-center align-items-center align-content-center justify-content-sm-center h-100">
                <div class="form-row">
                    <div class="col-sm-12">
                        <div class="form-row">
                            <div class="col-12 text-center">
                                <div class="logo"></div>
                                <h1>NOVA SENHA</h1>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12 text-center">
                                <p>Insira a sua nova senha e clique no botão 'ALTERAR SENHA'.</p>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12 text-center">
                                <b-form-group id="group-password" label="Nova senha*" label-for="password">
                                    <b-form-input id="password" v-model="form.password" type="password" min="5" max="200" required :state="validPassword" :disabled="loading"></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-12 text-center">
                                <b-form-group id="group-passwordConfirm" label="Confirme a nova senha*" label-for="passwordConfirm">
                                    <b-form-input id="passwordConfirm" v-model="form.passwordConfirm" type="password" min="5" max="200" required :state="validPassword" :disabled="loading"></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12 text-center">
                                <b-button variant="dark" size="lg" block type="submit" :disabled="loading">
                                    ALTERAR SENHA
                                    <b-icon v-show="loading" icon="arrow-clockwise" animation="spin" style="float: right; margin-top: 3px"></b-icon>
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-form>
        <div v-if=" etapa === 4 " class="h-100">
            <div class="container d-flex d-sm-flex flex-row justify-content-center align-items-center align-content-center justify-content-sm-center h-100">
                <div class="form-row">
                    <div class="col-sm-12">
                        <div class="form-row">
                            <div class="col-12 text-center">
                                <div class="logo"></div>
                                <h1>SENHA ALTERADA</h1>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12 text-center">
                                <p>Sua senha foi alterada com sucesso, agora você já pode se logar com a sua nova senha.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import store from '@/store';
import '../../assets/css/recovery.css';
import {Utils} from "../../components/utils";

export default {
    name: 'recovery',
    props: ['email', 'code'],
    mixins: [Utils],
    data() {
        return {
            etapa: 1,
            loading: false,
            form: {
                email: '',
                code: '',
                password: '',
                passwordConfirm: '',
            }
        };
    },
    methods: {
        // Step 1 -> Usuário informa email
        sendEmail(evt) {

            // Bloquear atualizar página por causa do submit
            evt.preventDefault();

            // Verificar se é um email válido
            if (this.isEmailValid(this.form.email)) {

                // Ativar loading
                this.loading = true;

                // Enviar email ao solicitante
                store.dispatch('esquecisenha', this.form.email)
                    .then((json) => {
                        if (json.Success === true) {
                            this.etapa = 2;
                        }
                        else {
                            alert(json.Message);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
            else {
                alert('Digite um email válido');
            }

        },
        // Step 2 -> Confirmar código de acesso
        validCode(evt) {

            // Bloquear atualizar página por causa do submit
            evt.preventDefault();

            this.validCodeAction();
        },
        validCodeAction() {
            // Ativar loading
            this.loading = true;

            // Verificar se o codigo esta correto
            const payload = {
                Token: this.form.code,
                Email: this.form.email,
            };
            store.dispatch('validarToken', payload)
                .then((json) => {
                    if (json.Success === true) {
                        this.etapa = 3;
                    }
                    else {
                        alert(json.Message);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        resendEmail(evt) {

            // Bloquear atualizar página por causa do submit
            evt.preventDefault();

            // Verificar se é um email válido
            if (this.isEmailValid(this.form.email)) {

                // Ativar loading
                this.loading = true;

                // Reenviar email ao solicitante
                store.dispatch('esquecisenha', this.form.email)
                    .then((json) => {
                        if (json.Success !== true) {
                            alert(json.Message);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }

        },
        // Step 3 -> Informar nova senha
        validPass(evt) {

            // Bloquear atualizar página por causa do submit
            evt.preventDefault();

            // Verificar se as senhas digitas são iguais
            if (this.form.password === this.form.passwordConfirm) {

                // Ativar loading
                this.loading = true;

                // Alterar senha
                const payload = {
                    Token: this.form.code,
                    Email: this.form.email,
                    NovaSenha: this.form.password,
                };
                store.dispatch('alterarsenha', payload)
                    .then((json) => {
                        if (json.Success === true) {
                            this.etapa = 4;
                        }
                        else {
                            alert(json.Message);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
            else {
                alert('As senhas não conferem');
            }
        }
    },
    computed: {
        validEmail() {
            const email = this.form.email;
            return email.trim() !== '' ? this.isEmailValid(email) : null;
        },
        validPassword() {
            const password = this.form.password;
            const passwordConfirm = this.form.passwordConfirm;

            if (password.trim() === '' || passwordConfirm.trim() === '') {
                return null;
            }

            return password === passwordConfirm;
        }
    },
    created() {

        if (this.email && this.code) {
            this.etapa = 2;
            this.form.email = this.email;
            this.form.code = this.code;
            this.validCodeAction();
        }

        document.title = 'Recuperar senha'
    }
}
</script>
